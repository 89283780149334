.MuiContainer-root.daily-reports-container {
  display: grid;
  grid-template-columns: 10ch 4em auto;
}

.daily-reports-container .daily-report-header {
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
}

.daily-reports-container .daily-report-content {
  border-top: 1px solid #000;
}

.works-container {
  display: grid;
  /* will generate by js
   grid-template-columns: 4em 4em 4em 4em;
   */
}

.works-container .work-header {
  text-align: center;
  vertical-align: middle;
}

.works-container .work-item {
  text-align: center;
}
