.withoutpadding input {
  padding: 5px;
}

.button-area {
  display: flex;
  justify-content: center;
}

.button {
  margin: 10px;
}

.flex {
  display: flex;
}

.content {
  overflow-y: 'auto';
  min-height: '74vh';
  max-height: '74vh';
}
.input-time {
  margin: 8px !important;
  width: 10em !important;
}

.header-tableCell {
  background-color: white !important;
  border-bottom-color: black !important;
  border-bottom-width: 1px !important;
}

.header-tableCell.matter {
  width: 20em;
}

.header-tableCell.workItem {
  width: 15em;
}

.header-tableCell.workTime {
  width: 7em;
}

.header-tableCell.note {
}

.header-tableCell.delete {
  width: 5em;
}

.body-tableCell {
  background-color: #ffffff;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
