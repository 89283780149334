.cell {
  cursor: pointer;
  border-radius: unset;
  margin: 1px;
  text-align: center;
}

.cell > .inner-cell {
  /* padding: 0.2em; */
  height: 100%;
}

.cell > .inner-cell > .summary {
  margin-top: 0;
  height: 20%;
}

.cell > .inner-cell > .body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  color: green;
}

.default {
  background-color: #F6F6F6;
}

.default:hover {
  background-color: rgb(231, 246, 239);
}

.saved {
  background-color: rgb(212, 252, 229);
}

.saved:hover {
  background-color: rgb(231, 246, 239);
}

.tempsaved {
  background-color: #f7e7fc;
}

.tempsaved:hover {
  background-color: #f7e7fc;
}

.saturday {
  color: #2F80ED;
  background-color: #edf4ff;
}

.saturday:hover {
  background-color: #f1f5fc;
}
.sunday {
  color: #EB5757;
  background-color: rgb(255, 236, 236);
}

.sunday:hover {
  background-color: rgb(253, 243, 243);
}

.outside {
  background-color: #E9E9E9;
}

.outside:hover {
  background-color: #EEEEEE;
}
