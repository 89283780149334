/*
 * 日報システムで使うカラーパレットを定義
 */
:root {
  /*
   * 色定義
   *
   * https://leonardocolor.io/theme.html
   * https://nipponcolors.com
   */
  --gray-100: #d6d6d6;
  --gray-200: #b5b5b5;
  --gray-300: #949494;
  --gray-400: #767676;
  --gray-500: #595959;
  --gray-600: #3d3d3d;

  /* セマンティック定義 */
  --color-default: var(--gray-600);
  --background-color-default: var(--gray-100);

  --color-button: var(--color-default);
  --background-color-button: var(--background-color-default);

  --color-header: var(--color-default);
  --background-color-header: var(--background-color-default);

  --color-sidemenu: var(--color-default);
  --background-color-sidemenu: var(--background-color-default);

  --color-link: var(--color-default);
  --background-color-link: var(--background-color-default);

  --color-link-active: var(--background-color-default);
  --background-color-link-active: var(--color-default);
}
