.MattersPage {
  width: 100vw;
  height: 100vh;

  color: var(--color-default);
  background-color: var(--background-color-default);
}

.MattersPage a:link,
.MattersPage a:visited,
.MattersPage a:hover,
.MattersPage a:active {
  color: var(--color-link);
  background-color: var(--background-color-link);
}

.MattersPage a:hover,
.MattersPage a:active {
  color: var(--color-link-active);
  background-color: var(--background-color-link-active);
}

.MattersPage button {
  border: none;
  border-radius: 5px;
  cursor: pointer;

  color: var(--color-button);
  background-color: var(--background-color-button);
}

:root {
  --header-height: 3em;
}

.MattersPage .Header {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--header-height);

  color: var(--color-header);
  background-color: var(--background-color-header);
}

.MattersPage .Header .container {
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.MattersPage .Header .icon-panel {
  display: block;
  width: 32px;
  text-align: center;
  margin: auto 0;
  cursor: pointer;
}

.MattersPage .Header .title-panel {
  display: block;
  width: calc(100vw - 25vw - 32px);
  margin: auto 0;
  font-size: 1.5em;
}

.MattersPage .Header .session-panel {
  display: block;
  width: 30vw;
  margin: auto 0;
  text: nowrap;
  text-align: right;
  padding-right: 0.5em;
}

.MattersPage .Header .session-panel .signout-button {
  margin-left: 0.5em;
}

.MattersPage .SideMenu {
  position: absolute;
  top: 0;
  width: 10em;
  height: 100vh;
  z-index: 1000;

  color: var(--color-sidemenu);
  background-color: var(--background-color-sidemenu);
}

@keyframes SideMenu-slide {
  0% {
    transform: translateX(-10em);
  }
  100% {
    transform: translateX(0);
  }
}

.MattersPage .SideMenu.open {
  display: block;
  animation: SideMenu-slide 0.2s forwards;
}

.MattersPage .SideMenu.close {
  display: none;
}

.MattersPage .SideMenu header {
  text-align: right;
}

.MattersPage .SideMenu .container {
  position: relative;
  padding-left: 1em;
}

.MattersPage .SideMenu ul {
  list-style: none;
  margin-top: 0.2em;
  padding-inline-start: 0.5em;
}

.MattersPage .SideMenu .container .category-label {
  font-size: 1.2em;
}

.MattersPage .SideMenu .container .menu-item {
  font-size: 1.1em;
}

.MattersPage .SideMenu .container .menu-item .menu-item-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-top: auto;
}

.MattersPage .SideMenu .container .menu-item .menu-item-icon .MuiSvgIcon-root {
  width: 100%;
  height: 100%;
}

.MattersPage .SideMenu .container .menu-item a:link {
  display: inline-block;
  width: 100%;
}

.MattersPage .SideMenu .container .menu-item a:link,
.MattersPage .SideMenu .container .menu-item a:visited,
.MattersPage .SideMenu .container .menu-item a:hover,
.MattersPage .SideMenu .container .menu-item a:active {
  text-decoration: none;
}

.MattersPage .PageMain {
  position: relative;
  top: var(--header-height);
  padding: 0 10vw;
  height: calc(100vh - var(--header-height));
  margin: 0 auto;
  overflow: scroll;
}

.MattersPage .matter-list {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
}

.MattersPage .matter-list .header,
.MattersPage .matter-list .item {
  padding: 0.2em;
}

.MattersPage .matter-list .header {
  font-weight: bold;
  text-align: center;
}

.MattersPage .matter-list .row:nth-child(even) {
  color: var(--background-color-default);
  background-color: var(--color-default);
}

.MattersPage .matter-list .item.code {
  text-align: center;
}

.MattersPage .matter-list .item.name {
  text-align: left;
}

.MattersPage .matter-list .item.date {
  text-align: right;
}

.MattersPage .matter-list .item.action {
  display: flex;
  gap: 0.2em;
}
