.cell {
  border-radius: unset;
  margin: 1px;
  text-align: center;
  height: 2em;
  background-color: #666666;
  color: white;
}

.cell > .inner-text {
  padding: 0.2em;
}
